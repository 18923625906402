import * as React from 'react'

import { FeePropagationViewer, Modal } from 'component'
import {
  Box,
  Button,
  Chip,
  CircularProgress,
  Stack,
  styled,
  SwitchProps,
  Typography,
} from '@mui/material'
import { getChipLabel, getModalCTA, removeRateGrid } from '../../core/ModalDeleteRateGrid.helpers'
import { IAPIInsuranceFeeUcitsDetail } from 'api/interfaces/entities'
import { InfoIcon } from 'icons'
import { Switch } from 'component/Inputs'

interface IProps {
  goToNextStep: () => void
  onCloseHandler: () => void
  providerLogo: string
  gridName: string
  insuranceName: string
  insuranceProductId: number
  deletedRateGrid: IAPIInsuranceFeeUcitsDetail
  setError: StateSetter<string | undefined>
}

const DeletionStep: React.FC<IProps> = (props) => {
  const {
    providerLogo,
    gridName,
    insuranceName,
    onCloseHandler,
    goToNextStep,
    insuranceProductId,
    deletedRateGrid,
    setError,
  } = props

  const [loading, setLoading] = React.useState(false)
  const [propagate, setPropagate] = React.useState(false)

  const insuranceProductsPropagatedTo = deletedRateGrid.insuranceProducts.filter(
    (product: any) => product.id !== insuranceProductId
  )
  const insuranceProductsPropagatedToCount = insuranceProductsPropagatedTo.length

  const deleteGridClickHandler = () => {
    removeRateGrid({
      setError,
      setLoading,
      propagate,
      deletedRateGridId: deletedRateGrid.id,
      insuranceProductsPropagatedToIds: insuranceProductsPropagatedTo.map((product) => product.id),
      goToNextStep,
    })
  }

  const propagateChangeHandler: SwitchProps['onChange'] = (e) => {
    setPropagate(e.target.checked)
  }

  return (
    <>
      <Modal.Content>
        <Stack direction="row" gap={1.5}>
          <img height={32} width={32} src={providerLogo} alt={`${insuranceName} logo`} />
          <Typography variant="h2">{gridName}</Typography>
        </Stack>
        {insuranceProductsPropagatedToCount !== 0 ? (
          <>
            <Stack
              direction="row"
              gap={1}
              mt={2}
              borderRadius={0.5}
              sx={(theme) => ({
                backgroundColor: 'primaryLight.main',
                color: 'primaryLight.contrastText',
                padding: theme.spacing(1, 1.5),
              })}
            >
              <InfoIcon sx={{ fontSize: 20 }} />
              <Box>
                <Typography variant="subtitle1" sx={{ color: 'primaryLight.contrastText' }}>
                  Cette grille est utilisée par {insuranceProductsPropagatedToCount} autres
                  produits.
                </Typography>
                <Typography variant="subtitle1" sx={{ color: 'primaryLight.contrastText' }}>
                  Vous pouvez propager la suppression à ces {insuranceProductsPropagatedToCount}{' '}
                  autres produits ou simplement supprimer la grille pour ce produit.
                </Typography>
              </Box>
            </Stack>
            <Stack flexDirection="row" justifyContent="space-between" alignItems="center" mt={3.5}>
              <Switch
                checked={propagate}
                onChange={propagateChangeHandler}
                label="Propager la suppression"
              />
              <Chip
                color="secondary"
                label={getChipLabel(insuranceProductsPropagatedToCount)}
                size="small"
              />
            </Stack>
            {propagate ? (
              <FeePropagationViewer
                insuranceProducts={insuranceProductsPropagatedTo}
                sx={{ mt: 2.5 }}
              />
            ) : null}
          </>
        ) : null}
      </Modal.Content>
      <Modal.Actions>
        <Stack flexGrow={1} direction="row" justifyContent="space-between">
          <Button variant="text" color="neutral" onClick={onCloseHandler}>
            Annuler
          </Button>
          <DeleteButton
            onClick={deleteGridClickHandler}
            endIcon={
              loading ? (
                <CircularProgress
                  sx={{ height: `20px !important`, width: `20px !important`, color: 'white' }}
                />
              ) : undefined
            }
            color="error"
          >
            {getModalCTA({
              propagate,
              insuranceProductsPropagatedToCount: insuranceProductsPropagatedToCount,
            })}
          </DeleteButton>
        </Stack>
      </Modal.Actions>
    </>
  )
}

export default DeletionStep

const DeleteButton = styled(Button)(({ theme }) => ({
  backgroundColor: theme.palette.error.light,
  color: 'white',

  '&:hover': {
    backgroundColor: '#C6564D',
  },
}))
