import { IAPIInsuranceFeeUcitsDetail } from 'api/interfaces'
import Modal from 'component/Modal'
import * as React from 'react'
import { DeletionStep, FinalStep } from './components'

interface IProps {
  open: boolean
  closeModal: () => void
  providerLogo: string
  gridName: string
  insuranceName: string
  insuranceProductId: number
  deletedRateGrid: IAPIInsuranceFeeUcitsDetail
}

const ModalDeleteRateGird: React.FC<IProps> = (props) => {
  const {
    open,
    closeModal,
    providerLogo,
    gridName,
    insuranceName,
    insuranceProductId,
    deletedRateGrid,
  } = props

  const [step, setStep] = React.useState(1)
  const [error, setError] = React.useState<string>()

  const resetState = () => {
    setStep(1)
  }

  const onCloseHandler = () => {
    resetState()
    closeModal()
  }

  const goToNextStep = () => {
    setStep((prevState) => prevState + 1)
  }

  return (
    <Modal
      title="Supprimer la grille"
      open={open}
      onClose={onCloseHandler}
      modalId="ModalDeleteRateGird"
    >
      {step === 1 ? (
        <DeletionStep
          providerLogo={providerLogo}
          gridName={gridName}
          insuranceName={insuranceName}
          onCloseHandler={onCloseHandler}
          insuranceProductId={insuranceProductId}
          deletedRateGrid={deletedRateGrid}
          goToNextStep={goToNextStep}
          setError={setError}
        />
      ) : step === 2 ? (
        <FinalStep error={error} resetState={resetState} />
      ) : null}
    </Modal>
  )
}

export default ModalDeleteRateGird
