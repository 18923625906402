import * as React from 'react'

import { Moment } from 'moment'
import { Modal } from 'component'
import { IAPIInsuranceProduct } from 'api/interfaces'
import { getProviderLogo } from 'utils/commissions'

import { FinalStep, ImportStep, UploadingStep } from './components'

interface IProps {
  open: boolean
  closeModal: () => void
  insuranceName: string
  insuranceProvider: string
  insuranceHelpURL?: string
  insuranceProductId: number
  insuranceProductName: string
  insuranceProducts: IAPIInsuranceProduct[]
}

const ModalUploadRateGrid: React.FC<IProps> = (props) => {
  const {
    open,
    closeModal,
    insuranceName,
    insuranceProvider,
    insuranceHelpURL,
    insuranceProductId,
    insuranceProductName,
    insuranceProducts,
  } = props

  const [step, setStep] = React.useState(1)
  const [file, setFile] = React.useState<File>()
  const [periodStart, setPeriodStart] = React.useState<Moment | null>(null)
  const [name, setName] = React.useState('')
  const [category, setCategory] = React.useState<string>()
  const [error, setError] = React.useState<string>()
  const [selectedInsuranceProductsIds, setSelectedInsuranceProductsIds] = React.useState<number[]>(
    []
  )

  const isNortia = insuranceProvider === 'nortia'
  const providerLogo = getProviderLogo(insuranceProvider)

  const resetState = () => {
    setStep(1)
    setFile(undefined)
    setName('')
    setPeriodStart(null)
    setCategory(undefined)
    setError(undefined)
    setSelectedInsuranceProductsIds([])
  }

  React.useEffect(() => {
    if (open === true) resetState()
  }, [open])

  const goToNextStep = () => {
    setStep((prevState) => prevState + 1)
  }

  const insuranceProductsWithoutCurrentInsuranceProduct = insuranceProducts.filter(
    (product) => product.id !== insuranceProductId
  )

  return (
    <Modal
      open={open}
      onClose={() => closeModal()}
      title="Importer une grille de taux"
      modalId="ModalUploadRateGrid"
    >
      {step === 1 ? (
        <ImportStep
          goToNextStep={goToNextStep}
          providerLogo={providerLogo}
          insuranceName={insuranceName}
          insuranceHelpURL={insuranceHelpURL}
          insuranceProductName={insuranceProductName}
          file={file}
          setFile={setFile}
          periodStart={periodStart}
          setPeriodStart={setPeriodStart}
          name={name}
          setName={setName}
          category={category}
          setCategory={setCategory}
          isNortia={isNortia}
          insuranceProducts={insuranceProductsWithoutCurrentInsuranceProduct}
          selectedInsuranceProductsCount={selectedInsuranceProductsIds.length}
          setSelectedInsuranceProductsIds={setSelectedInsuranceProductsIds}
        />
      ) : step === 2 && file !== undefined && name !== '' && periodStart !== null ? (
        <UploadingStep
          selectedInsuranceProductsIds={
            isNortia ? [insuranceProductId] : [insuranceProductId, ...selectedInsuranceProductsIds]
          }
          setError={setError}
          file={file}
          name={name}
          periodStart={periodStart}
          category={category}
          goToNextStep={goToNextStep}
        />
      ) : step === 3 && name !== '' ? (
        <FinalStep name={name} error={error} resetState={resetState} />
      ) : null}
    </Modal>
  )
}

export default ModalUploadRateGrid
