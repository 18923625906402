import { API_UCITS_FEE_URL } from 'api/constants'
import { fetchDelete, patch } from 'utils'

export const deleteRateGrid = (deletedRateGridId: number) => {
  return fetchDelete({ url: `${API_UCITS_FEE_URL}/${deletedRateGridId}` })
}

export const patchRateGridPropagation = ({
  deletedRateGridId,
  insuranceProductsPropagatedToIds,
}: {
  deletedRateGridId: number
  insuranceProductsPropagatedToIds: number[]
}) => {
  const body = {
    insuranceProductIds: insuranceProductsPropagatedToIds,
  }

  return patch({
    url: `${API_UCITS_FEE_URL}/${deletedRateGridId}`,
    body: JSON.stringify(body),
    headers: { 'Content-Type': 'application/json' },
  })
}
