import * as React from 'react'
import { connect } from 'react-redux'
import { SelectChangeEvent, Stack, Typography } from '@mui/material'
import { BaseTable } from 'component/Tables'
import { CONTRACTS_AFFILIATION_COLUMNS } from 'component/Tables/DocketsTable/columns'
import { Dropdown, SearchInput } from 'component/Inputs'
import { useDebounce } from 'utils'
import MuiTablePagination from 'component/Tables/Pagination/MuiTablePagination'
import ModalClientContractAffiliation from 'component/Modals/ModalClientContractAffiliation'
import {
  getContractsInsurances,
  getContractsProducts,
  getContractsToAffiliate,
} from '../../core/Clients.services'
import { IAPICommissionContractToAffiliate } from 'api/interfaces/entities'
import { IDropDownOption } from 'component/Inputs/Dropdown'
import { getProviderLogo } from 'utils/commissions'

interface IProps {
  user: any
  initialContractsFetch: {
    contracts: IAPICommissionContractToAffiliate[]
    contractsCount: number
  } | null
}

const ClientsAffiliation: React.FC<IProps> = (props) => {
  const { user, initialContractsFetch } = props
  const [loading, setLoading] = React.useState(true)
  const [searchTerms, setSearchTerms] = React.useState<string>('')
  const [insurancesFilters, setInsurancesFilters] = React.useState<IDropDownOption[]>([
    {
      display: 'Tous les assureurs',
    },
  ])
  const [productsFilters, setProductsFilters] = React.useState<IDropDownOption[]>([
    {
      display: 'Tous les produits',
    },
  ])
  const [selectedFilter, setSelectedFilter] = React.useState<{
    productId?: string
    insuranceId?: string
  }>({})
  const debounceSearchTerms = useDebounce(searchTerms, 800)
  const [page, setPage] = React.useState(0)
  const [perPage, setPerPage] = React.useState(25)
  const [contracts, setContracts] = React.useState<IAPICommissionContractToAffiliate[]>([])
  const [total, setTotal] = React.useState(0)
  const [openContract, setOpenContract] = React.useState<IAPICommissionContractToAffiliate | null>(
    null
  )
  const [filterParams, setFilterParams] = React.useState({
    page,
    count: perPage,
    search: debounceSearchTerms,
    productId: selectedFilter.productId,
    insuranceId: selectedFilter.insuranceId,
  })

  React.useEffect(() => {
    if (initialContractsFetch) {
      setLoading(false)
      setContracts(initialContractsFetch?.contracts)
      setTotal(initialContractsFetch?.contractsCount)
    }
  }, [initialContractsFetch])

  React.useEffect(() => {
    if (
      (!loading && debounceSearchTerms !== filterParams.search) ||
      selectedFilter.productId !== filterParams.productId ||
      selectedFilter.insuranceId !== filterParams.insuranceId ||
      page !== 0 ||
      perPage !== filterParams.count
    ) {
      setPage(0)
      setFilterParams({
        page: 0,
        count: perPage,
        search: debounceSearchTerms,
        productId: selectedFilter.productId,
        insuranceId: selectedFilter.insuranceId,
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debounceSearchTerms, selectedFilter])

  React.useEffect(() => {
    if ((page !== filterParams.page || perPage !== filterParams.count) && !loading) {
      setFilterParams({
        page,
        count: perPage,
        search: debounceSearchTerms,
        productId: selectedFilter.productId,
        insuranceId: selectedFilter.insuranceId,
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, perPage])

  const fetchContracts = React.useCallback(async () => {
    const res = await getContractsToAffiliate({
      filterParams,
      setLoading,
    })

    if (!res) {
      return
    }

    setTotal(res.contractsCount)
    setContracts(res.contracts)
  }, [filterParams])

  const fetchInsurancesFilters = async () => {
    const res = await getContractsInsurances(true)
    if (!res) {
      return
    }
    setInsurancesFilters([
      {
        display: 'Tous les assureurs',
      },
      ...res.insurances.map((insurance) => ({
        value: insurance.id.toString(),
        display: insurance.provider,
        logo: getProviderLogo(insurance.provider),
      })),
    ])
  }

  const fetchProductsFilters = React.useCallback(async () => {
    if (!selectedFilter.insuranceId) return
    const res = await getContractsProducts({ insuranceId: selectedFilter.insuranceId })
    if (!res) {
      return
    }
    setProductsFilters([
      {
        display: 'Tous les produits',
      },
      ...res.products.map((product) => ({
        value: product.id.toString(),
        display: product.name,
      })),
    ])
  }, [selectedFilter.insuranceId])

  React.useEffect(() => {
    fetchInsurancesFilters()
  }, [])

  React.useEffect(() => {
    fetchProductsFilters()
  }, [fetchProductsFilters])

  React.useEffect(() => {
    if (!loading) {
      fetchContracts()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterParams, fetchContracts])

  const updateSearch = (value: string) => {
    setSearchTerms(value)
  }

  // @ts-ignore
  const handleChangePage = (event: any, page: number) => {
    setPage(page)
  }
  const handleChangeRowsPerPage = (event: React.BaseSyntheticEvent) => {
    setPage(0)
    setPerPage(event.target.value)
  }

  const updateProductsFilter = (event: SelectChangeEvent<unknown>) => {
    setSelectedFilter((prevState) => ({
      ...prevState,
      productId: event.target.value as string,
    }))
  }

  const updateInsurersFilter = (event: SelectChangeEvent<unknown>) => {
    setSelectedFilter({
      insuranceId: event.target.value as string,
    })
  }

  const handleClientAttachement = () => {
    fetchContracts()
    setOpenContract(null)
  }

  const COLUMNS: any = React.useMemo(() => {
    return CONTRACTS_AFFILIATION_COLUMNS(setOpenContract, user)
  }, [setOpenContract, user])

  if (!user.organization.enableReports) {
    window.location.href = '/'
    return <></>
  }

  return (
    <div className="container commissions--container">
      <Stack direction="row" alignItems="center" mb={2}>
        <SearchInput
          id="search"
          value={searchTerms}
          onChange={(e) => updateSearch(e.target.value)}
          onReset={() => updateSearch('')}
          size="small"
          sx={{ mr: 1 }}
        />
        <Dropdown
          id="insurers-filter"
          value={
            insurancesFilters.find((filter) => filter.value === selectedFilter.insuranceId)?.value
          }
          onChange={updateInsurersFilter}
          placeholder="Filtrer par"
          options={insurancesFilters}
          size="small"
          sx={{ mr: 1 }}
        />
        <Dropdown
          id="products-filter"
          value={productsFilters.find((filter) => filter.value === selectedFilter.productId)?.value}
          onChange={updateProductsFilter}
          placeholder="Filtrer par"
          options={productsFilters}
          size="small"
          sx={{ mr: 1 }}
          disabled={!selectedFilter.insuranceId}
        />
        <MuiTablePagination
          count={total}
          rowsPerPage={perPage}
          page={page}
          handleChangePage={handleChangePage}
          handleChangeRowsPerPage={handleChangeRowsPerPage}
          labelRowsPerPage="Contrats par page"
          sx={{ width: 'auto', marginLeft: 'auto' }}
        />
      </Stack>
      <BaseTable
        autoHeight
        rowHeight={56}
        columnHeaderHeight={56}
        columns={COLUMNS}
        rows={contracts}
        noContentMessage="Aucun client à afficher."
        defaultGroupingExpansionDepth={-1}
        pagination={false}
        slots={{
          noRowsOverlay: () => (
            <Stack height="100%" alignItems="center" justifyContent="center">
              {searchTerms ? (
                <Typography variant="subtitle1" component="p" color="text.secondary" px={1}>
                  Aucun client ne correspond à votre recherche.
                </Typography>
              ) : (
                <>
                  <Typography variant="subtitle1" component="p" color="text.secondary" px={1}>
                    Aucun client à afficher.
                  </Typography>
                </>
              )}
            </Stack>
          ),
        }}
        loading={loading}
        sx={{ overflow: 'hidden' }}
      />
      <ModalClientContractAffiliation
        open={!!openContract}
        onClose={handleClientAttachement}
        contract={openContract}
      />
    </div>
  )
}

const mapStateToProps = (state: any) => {
  return {
    user: state.user,
  }
}

export default connect(mapStateToProps)(ClientsAffiliation)
