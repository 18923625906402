import { API_UCITS_FEE_URL } from 'api/constants'
import { fetchDelete } from 'utils'

export const deleteUcitsRateGridItem = ({
  ucitsGridId,
  ucitsGridItemId,
}: {
  ucitsGridId: number
  ucitsGridItemId: number
}) => {
  return fetchDelete({ url: `${API_UCITS_FEE_URL}/${ucitsGridId}/items/${ucitsGridItemId}` })
}
