import * as React from 'react'

import { Moment } from 'moment'
import { Modal, FileUploader, InsuranceProductsSelector } from 'component'
import { BaseInput, DateInput, Switch, ToggleButtonGroup } from 'component/Inputs'
import { IAPIInsuranceProduct } from 'api/interfaces'
import { IToggleButtonGroupButton } from 'component/Inputs/ToggleButtonGroup'

import {
  getChipLabel,
  handleFileDelete,
  handleFileUploaderFileChange,
  handleNameChange,
  handlePeriodStartChange,
} from '../../core/ModalUploadRateGrid.helpers'

import { Button, Chip, Stack, SwitchProps, ToggleButtonGroupProps, Typography } from '@mui/material'

interface IProps {
  goToNextStep: () => void
  providerLogo: string
  insuranceName: string
  insuranceHelpURL?: string
  insuranceProductName: string
  file: File | undefined
  setFile: StateSetter<File | undefined>
  periodStart: Moment | null
  setPeriodStart: StateSetter<Moment | null>
  name: string
  setName: StateSetter<string>
  category: string | undefined
  setCategory: StateSetter<string | undefined>
  isNortia: boolean
  insuranceProducts: IAPIInsuranceProduct[]
  selectedInsuranceProductsCount: number
  setSelectedInsuranceProductsIds: StateSetter<number[]>
}

const ImportStep: React.FC<IProps> = (props) => {
  const {
    goToNextStep,
    providerLogo,
    insuranceName,
    insuranceHelpURL,
    insuranceProductName,
    periodStart,
    setPeriodStart,
    file,
    setFile,
    name,
    setName,
    category,
    setCategory,
    isNortia,
    insuranceProducts,
    selectedInsuranceProductsCount,
    setSelectedInsuranceProductsIds,
  } = props

  const CATEGORY_BUTTONS: IToggleButtonGroupButton[] = [
    {
      display: 'Cat. 2',
      value: 'CAT 2',
    },
    {
      display: 'Cat. 3',
      value: 'CAT 3',
    },
    {
      display: 'Cat. 4',
      value: 'CAT 4',
    },
  ]

  const [propagate, setPropagate] = React.useState(false)

  const categoryChangeHandler: ToggleButtonGroupProps['onChange'] = (e, value) => {
    setCategory(value)
  }

  const insuranceProductsSelectionChangeHandler = (newSelectedInsuranceProductIds: number[]) => {
    setSelectedInsuranceProductsIds(newSelectedInsuranceProductIds)
  }

  const propagateChangeHandler: SwitchProps['onChange'] = (e) => {
    setPropagate(e.target.checked)
  }

  const disabled = isNortia
    ? category === undefined || periodStart === null || file === undefined || name === ''
    : periodStart === null || file === undefined || name === ''

  return (
    <>
      <Modal.Content>
        <Stack flexDirection="row" alignItems="center" mb={2} gap={1.5}>
          <img src={providerLogo} alt={`${insuranceName} logo`} width={32} />
          <Typography variant="h2b" fontWeight={500} color="text.primary">
            {insuranceProductName}
          </Typography>
        </Stack>
        <FileUploader
          formats={['pdf', 'xls', 'xlsx', 'csv']}
          sizeLimit="5mb"
          onChange={handleFileUploaderFileChange(setFile)}
          onReset={handleFileDelete(setFile)}
          file={file}
          sx={{ mb: 2 }}
          helpURL={insuranceHelpURL || undefined}
        />
        <Stack gap={2} flexDirection="row">
          <BaseInput
            id="name"
            value={name}
            onChange={handleNameChange(setName)}
            label="Nom de la grille"
            placeholder="Nommez votre grille"
            sx={{ flexBasis: '50%' }}
          />
          <DateInput
            id="periodStart"
            label="Date de prise d'effet de la grille"
            value={periodStart}
            onChange={handlePeriodStartChange(setPeriodStart)}
            placeholder="Selectionnez une date"
            sx={{ flexBasis: '50%' }}
            views={['day', 'month', 'year']}
            openTo="day"
          />
        </Stack>
        {isNortia ? (
          <ToggleButtonGroup
            sx={{ mt: 2 }}
            label="Catégorie de votre cabinet"
            exclusive
            buttons={CATEGORY_BUTTONS}
            value={category}
            onChange={categoryChangeHandler}
            hint={
              <>
                <Typography variant="subtitle1">
                  Nortia ordonne ses apporteurs d’affaires par catégorie. Cette catégorie détermine
                  votre taux de rétrocession.
                </Typography>
                <Typography variant="subtitle1">
                  Vous pouvez retrouver cette catégorie dans votre convention de commission ou en la
                  demandant à votre interlocuteur commerciale.
                </Typography>
              </>
            }
          />
        ) : (
          <>
            <Stack flexDirection="row" justifyContent="space-between" alignItems="center" mt={3.5}>
              <Switch
                checked={propagate}
                onChange={propagateChangeHandler}
                label="Propager la règle à d’autres produits ou familles"
              />
              <Chip
                color="secondary"
                label={getChipLabel(selectedInsuranceProductsCount)}
                size="small"
                sx={(theme) => ({
                  opacity: propagate ? 1 : 0,
                  transition: theme.transitions.create('opacity', {
                    duration: theme.transitions.duration.short,
                    easing: theme.transitions.easing.easeOut,
                  }),
                })}
              />
            </Stack>
            {propagate ? (
              <InsuranceProductsSelector
                insuranceProducts={insuranceProducts}
                onChange={insuranceProductsSelectionChangeHandler}
                sx={{ mt: 2.5 }}
              />
            ) : null}
          </>
        )}
      </Modal.Content>
      <Modal.Actions>
        <Stack flexGrow={1} flexDirection="row" justifyContent="flex-end">
          <Button onClick={goToNextStep} disabled={disabled}>
            Importer la grille de taux
          </Button>
        </Stack>
      </Modal.Actions>
    </>
  )
}

export default ImportStep
