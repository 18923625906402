import EventEmitter, { UPDATE_EVENT_INSURANCE_PRODUCTS } from 'utils/events'

import { deleteRateGrid, patchRateGridPropagation } from './ModalDeleteRateGrid.service'

export const getModalCTA = ({
  propagate,
  insuranceProductsPropagatedToCount,
}: {
  propagate: boolean
  insuranceProductsPropagatedToCount: number
}): string => {
  if (propagate === false) {
    return 'Supprimer la grille pour ce produit'
  }

  return insuranceProductsPropagatedToCount > 0
    ? `Supprimer la grille pour les ${insuranceProductsPropagatedToCount + 1} produits`
    : 'Supprimer la grille pour ce produit'
}

export const getChipLabel = (insuranceProductsPropagatedToCount: number) => {
  if (insuranceProductsPropagatedToCount > 1) {
    return `Grille propagée à ${insuranceProductsPropagatedToCount} produits`
  }

  return `Grille propagée à ${insuranceProductsPropagatedToCount} produit`
}

export const removeRateGrid = async ({
  setError,
  setLoading,
  propagate,
  deletedRateGridId,
  insuranceProductsPropagatedToIds,
  goToNextStep,
}: {
  setError: StateSetter<string | undefined>
  setLoading: StateSetter<boolean>
  propagate: boolean
  deletedRateGridId: number
  insuranceProductsPropagatedToIds: number[]
  goToNextStep: () => void
}) => {
  setError(undefined)
  setLoading(true)

  if (propagate || insuranceProductsPropagatedToIds.length === 0) {
    await deleteRateGrid(deletedRateGridId).catch((err) => {
      setError(
        `Erreur lors de la suppression de la grille de taux, veuillez réessayer. Si le problème persiste, contactez le support Sendraise.`
      )
    })
  } else {
    await patchRateGridPropagation({ deletedRateGridId, insuranceProductsPropagatedToIds }).catch(
      (err) => {
        setError(
          `Erreur lors de la suppression de la grille de taux, veuillez réessayer. Si le problème persiste, contactez le support Sendraise.`
        )
      }
    )
  }

  setLoading(false)
  goToNextStep()

  EventEmitter.dispatch(UPDATE_EVENT_INSURANCE_PRODUCTS)
}
