import { CustomHook } from 'api/interfaces'
import { Context } from 'page/Commissions/Contracts'
import { useContext } from 'react'

export const useReportingSettingsNavbar: CustomHook<void, [(id: string) => boolean]> = () => {
  const context = useContext(Context)
  const { selectedInsuranceProduct } = { ...context }

  const showWarning = (id: string) => {
    if (selectedInsuranceProduct === undefined) {
      return false
    }

    if (
      (id === 'opcvm' && selectedInsuranceProduct.missingUcits) ||
      (id === 'arbitrages' && selectedInsuranceProduct.missingInsuranceFeeArbitrage) ||
      (id === 'versements' && selectedInsuranceProduct.missingInsuranceFeeDeposit) ||
      (id === 'upfronts' && selectedInsuranceProduct.missingInsuranceFeeUpfront)
    ) {
      return true
    }

    return false
  }

  return [showWarning]
}