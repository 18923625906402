import { API_USER_INSURANCE_URL } from 'api/constants'
import { customFetch } from 'api/customFetch'
import { CustomHook, IAPIDocketFilter, IAPIDocketItem } from 'api/interfaces'
import { Dispatch, SetStateAction, useEffect } from 'react'

export const useDockets: CustomHook<
  {
    setIsDataLoading: Dispatch<SetStateAction<boolean>>
    setContractCount: Dispatch<SetStateAction<number>>
    setDocketItems: Dispatch<SetStateAction<IAPIDocketItem[]>>
    setDocketItemCount: Dispatch<SetStateAction<number>>
    setIsRevalidable: Dispatch<SetStateAction<boolean>>
    filterParams: IAPIDocketFilter
  },
  void
> = ({
  setIsDataLoading,
  setContractCount,
  setDocketItems,
  setDocketItemCount,
  setIsRevalidable,
  filterParams,
}) => {
  useEffect(() => {
    const fetchData = () => {
      setIsDataLoading(true)

      let queryParams = new URLSearchParams()

      queryParams.append('action', 'list')
      queryParams.append('type', filterParams.commissionType)
      queryParams.append('offset', filterParams.contractOffset.toString())
      queryParams.append('count', '10')

      if (filterParams.selectedPeriod) {
        queryParams.append('year', filterParams.selectedPeriod.year.toString())
        queryParams.append('month', filterParams.selectedPeriod.month.toString())
      }

      if (filterParams.selectedDisplayFilterIndex === 1) {
        queryParams.append('filter', 'error_detected')
      }

      if (filterParams.searchTerms) {
        queryParams.append('q', filterParams.searchTerms)
      }

      customFetch
        .get(
          `${API_USER_INSURANCE_URL}/${
            filterParams.selectedInsurance?.id
          }/insurance-fee-report-items?${queryParams.toString()}`
        )
        .then((res) => {
          if (!res.ok) {
            throw res.json()
          }

          if (res.headers.has('X-Item-Count')) {
            const itemCount = parseInt(res.headers.get('X-Item-Count')!)
            setDocketItemCount(itemCount)
          }

          if (res.headers.has('X-Contract-Count')) {
            const count = parseInt(res.headers.get('X-Contract-Count')!)
            setContractCount(count)
          }

          return res.json()
        })
        .then((data: IAPIDocketItem[]) => {
          setIsDataLoading(false)
          setDocketItems(data)
        })
        .catch((error) => {
          setIsDataLoading(false)
          console.log(`Get docket items error : ${error}`)
        })
    }

    fetchData()
  }, [
    setContractCount,
    setDocketItems,
    setDocketItemCount,
    setIsDataLoading,
    setIsRevalidable,
    filterParams,
  ])
}
