import { IAPICommissionClient } from 'api/interfaces/entities'
import { customFetch } from 'api/customFetch'
import { API_CONTRACTS_URL, API_REPORTING_CLIENTS_URL } from 'api/constants'

export const attachContractToClient = async ({
  clientId,
  contractId,
}: {
  clientId: number
  contractId: number
}) => {
  try {
    const res = await customFetch
      .post(`${API_CONTRACTS_URL}/${contractId}/reporting-clients/${clientId}/attach`, {})
      .catch((err) => {
        throw new Error(err)
      })

    if (!res || !res.ok) {
      throw new Error(res.statusText)
    }
  } catch (e: any) {
    throw new Error(e)
  }
}

export const detachContractFromReportingClient = async ({
  clientId,
  contractId,
}: {
  clientId: number
  contractId: number
}) => {
  try {
    const res = await customFetch
      .post(`${API_CONTRACTS_URL}/${contractId}/reporting-clients/${clientId}/detach`, {})
      .catch((err) => {
        throw new Error(err)
      })

    if (!res || !res.ok) {
      throw new Error(res.statusText)
    }
  } catch (e: any) {
    throw new Error(e)
  }
}

export const getFilteredClients = async ({
  search,
}: {
  search: string
}): Promise<
  | {
      clients: IAPICommissionClient[]
    }
  | undefined
> => {
  const params = new URLSearchParams()

  if (search) {
    params.append('q', search)
  }

  const res = await customFetch
    .get(
      `${API_REPORTING_CLIENTS_URL}${params.getAll.length > 0 ? `?${params.toString()}` : null}`
    )
    .catch((err) => {
      console.log(err)
    })
  if (!res || !res.ok) {
    return undefined
  }

  const jsonRes: any = await res.json()

  return {
    clients: jsonRes.clients as IAPICommissionClient[],
  }
}
