import { API_UCITS_FEE_URL } from 'api/constants'
import {
  IAPIInsuranceFeeUcits,
  IAPIInsuranceFeeUcitsItem,
  IAPIInsuranceFeeUcitsDetail,
} from 'api/interfaces'
import { get } from 'utils'

export const getUcitsGrids = (selectedInsuranceProductId: number) => {
  const params = new URLSearchParams()

  params.append('insuranceProduct', selectedInsuranceProductId.toString())

  const url = `${API_UCITS_FEE_URL}?${params.toString()}`

  return get<IAPIInsuranceFeeUcits[]>({ url })
}

export const getUcitsGridDetail = (selectedUcitsGridId: number) => {
  return get<IAPIInsuranceFeeUcitsDetail>({ url: `${API_UCITS_FEE_URL}/${selectedUcitsGridId}` })
}

export const getUcitsGridItems = (selectedUcitsGridId: number, search: string) => {
  if (search !== '') {
    const params = new URLSearchParams()

    params.append('q', search)

    return get<IAPIInsuranceFeeUcitsItem[]>({
      url: `${API_UCITS_FEE_URL}/${selectedUcitsGridId}/items?${params.toString()}`,
    })
  }

  return get<IAPIInsuranceFeeUcitsItem[]>({
    url: `${API_UCITS_FEE_URL}/${selectedUcitsGridId}/items`,
  })
}
