import {
  Alert,
  Box,
  Button,
  FormControlLabel,
  Radio,
  RadioGroup,
  Snackbar,
  Stack,
  Typography,
  styled,
} from '@mui/material'
import * as React from 'react'
import { Modal } from 'component'
import { AlertCircleIcon, ArrowRightIcon } from 'icons'
import { getProviderLogo } from 'utils/commissions'
import Theme from 'theme'
import { SearchInput } from 'component/Inputs'
import { attachContractToClient, getFilteredClients } from './core/ClientContract.services'
import { IAPICommissionClient, IAPICommissionContractToAffiliate } from 'api/interfaces/entities'
import { useDebounce } from 'utils'
import EventEmitter, { UPDATE_EVENT_CONTRACTS } from 'utils/events'

export interface IProps {
  open: boolean
  onClose: () => void
  contract: IAPICommissionContractToAffiliate | null
}

const ModalClientContractAffiliation: React.FC<IProps> = (props) => {
  const { open, onClose, contract } = props
  const [error, setError] = React.useState('')
  const [search, setSearch] = React.useState('')
  const [selectedClient, setSelectedClient] = React.useState<number | null>(null)
  const [clients, setClients] = React.useState<IAPICommissionClient[]>([])
  const debounceSearch = useDebounce(search, 800)

  React.useEffect(() => {
    if (!open) {
      setSearch('')
    }
  }, [open])

  const handleClientSearch = React.useCallback(async () => {
    const res = await getFilteredClients({ search: debounceSearch })
    if (!res) {
      return
    }
    setClients(res.clients)
  }, [debounceSearch])

  React.useEffect(() => {
    if (debounceSearch) {
      handleClientSearch()
    } else {
      setClients([])
    }
  }, [debounceSearch, handleClientSearch])

  const handleTextChange = (
    setter: StateSetter<string>
  ): React.ChangeEventHandler<HTMLInputElement> => {
    return (e) => {
      setter(e.target.value)
    }
  }

  const handleResetClick = (setter: StateSetter<string>): React.MouseEventHandler => {
    return () => {
      setter('')
    }
  }

  const handleChange = (e: React.BaseSyntheticEvent) => {
    setSelectedClient(Number(e.target.value))
  }

  const handleAffiliation = async () => {
    if (!contract || !selectedClient) return
    try {
      await attachContractToClient({ clientId: selectedClient, contractId: contract.id })
      EventEmitter.dispatch(UPDATE_EVENT_CONTRACTS)
      onClose()
    } catch (e: any) {
      setError(e.message)
    }
  }

  if (!contract) {
    return <></>
  }

  return (
    <Modal
      open={open}
      onClose={onClose}
      title="Rattacher un contrat à un client de votre base"
      modalId="ModalClientContractAffiliation"
    >
      <Modal.Content>
        <Stack
          direction="row"
          color="primaryLight.contrastText"
          bgcolor="primaryLight.main"
          py={1}
          px={2}
          mb={4}
        >
          <AlertCircleIcon />
          <Typography ml={1} variant="subtitle1" color="inherit">
            Ce contrat sera automatiquement attaché à un client existant de votre base. Toutes ses
            données et commissions seront fusionnées avec ses contrats automatisés.
          </Typography>
        </Stack>
        <Stack
          direction="row"
          justifyContent="space-between"
          bgcolor="background.default"
          border={`1px dashed ${Theme.palette.divider}`}
          borderRadius={2}
          p={2}
          mb={4}
        >
          <Box>
            <Typography variant="body2_m">{contract.number}</Typography>
            <Typography variant="subtitle1" sx={{ fontStyle: 'italic', opacity: 0.7 }}>
              {contract.lastName + ' ' + contract.firstName}
            </Typography>
          </Box>
          <Stack gap={1} direction="row" alignItems="center">
            <Typography variant="subtitle1_m">{contract.insuranceProduct?.name}</Typography>
            <img
              width="24px"
              height="24px"
              src={getProviderLogo(contract.insurance?.provider)}
              alt={contract.insurance?.provider}
            />
          </Stack>
        </Stack>
        <SearchInput
          id="search"
          sx={{ width: '100%' }}
          label="Rattacher à"
          value={search}
          onChange={handleTextChange(setSearch)}
          onReset={handleResetClick(setSearch)}
          placeholder="Rechercher un client"
        />
        <form onChange={handleChange}>
          <RadioGroup name="client-input" sx={{ mt: 2 }}>
            {clients.map((client) => (
              <CustomFormControlLabel
                key={client.id}
                value={client.id}
                control={<Radio />}
                sx={{ border: `1px solid ${Theme.palette.divider}`, borderRadius: 1, mb: 1 }}
                label={
                  <Stack direction="row" justifyContent="space-between" width="100%" pr={2}>
                    <Typography variant="subtitle1_m">{`${client.firstName} ${client.lastName}`}</Typography>
                    <Stack direction="row" gap={1}>
                      {client.insurances.map((insurance) => (
                        <img
                          width="20px"
                          height="20px"
                          src={getProviderLogo(insurance)}
                          alt={insurance}
                          key={insurance}
                        />
                      ))}
                      <Typography component="span" variant="subtitle1" sx={{ opacity: 0.7 }}>
                        {client.totalContractCount}{' '}
                        {client.totalContractCount > 1 ? 'contrats' : 'contrat'}
                      </Typography>
                    </Stack>
                  </Stack>
                }
                disableTypography
              />
            ))}
          </RadioGroup>
        </form>
      </Modal.Content>
      <Modal.Actions>
        <Stack flexGrow={1} direction="row" justifyContent="flex-end">
          <Button
            endIcon={<ArrowRightIcon />}
            onClick={handleAffiliation}
            disabled={!selectedClient}
          >
            Rattacher
          </Button>
        </Stack>
      </Modal.Actions>
      <Snackbar open={!!error} autoHideDuration={6000} onClose={() => setError('')}>
        <Alert
          onClose={() => setError('')}
          severity="error"
          variant="filled"
          sx={{ width: '100%', alignItems: 'center' }}
        >
          {error}
        </Alert>
      </Snackbar>
    </Modal>
  )
}

export default ModalClientContractAffiliation

const CustomFormControlLabel = styled(FormControlLabel)(({ theme }) => ({
  transition: `0.3s ${theme.transitions.easing.easeIn}`,
  ':has(input:checked)': {
    background: theme.palette.primaryLight.main,
    border: `1px solid ${theme.palette.primary.main}`,
  },
}))
