import * as React from 'react'

import { getInsrPrdsFamGroupings, urlFormat } from 'utils'
import { IAPIInsurance, IAPIInsuranceProduct } from 'api/interfaces'
import { LayoutMenu } from 'component/Layouts'

import { updateInsrPrds, updateInsrs } from './core/Contracts.helpers'
import { ContractsSidePanel } from './components'

import { Box, Stack } from '@mui/material'

// Legacy
import LayoutCommission from 'component/Layouts/LayoutCommissions'
import ReportingSettingsNavbar from 'component/ReportingSettings/ReportingSettingsNavbar'
import ReportingSettingsHeader from 'component/ReportingSettings/ReportingSettingsHeader'
import EventEmitter, { UPDATE_EVENT_INSURANCE_PRODUCTS } from 'utils/events'
import { REPORTING_CONFIGURATION_URL } from 'utils/router/constants'
import { useHistory, useParams } from 'react-router-dom'
import { UCITS_NAVBAR_ITEMS } from 'component/ReportingSettings/ReportingSettingsNavbar/typescript/reportingSettingsNavbar.constants'

// Legacy
export const Context = React.createContext<
  | {
      selectedInsuranceProduct?: IAPIInsuranceProduct
      selectedInsurance?: IAPIInsurance
      insurances?: IAPIInsurance[]
      insuranceProducts?: IAPIInsuranceProduct[]
    }
  | undefined
>(undefined)

export interface IURLParams {
  insuranceProvider?: string
  insuranceFamily?: string
  insuranceProductId?: string
  commissionType?: string
}

const Contracts: React.FC = () => {
  const {
    insuranceProvider: paramInsuranceProvider,
    insuranceFamily: paramInsuranceFamily,
    insuranceProductId: insuranceProductIdStr,
    commissionType: commissionTypeStr,
  } = useParams<IURLParams>()

  const [insrsError, setInsrsError] = React.useState<string>()
  const [insrsLoading, setInsrsLoading] = React.useState(false)
  const [insrs, setInsrs] = React.useState<IAPIInsurance[]>()
  const [selectedInsrIdx, setSelectedInsrIdx] = React.useState<number>()
  const [selectedInsrFamIdx, setSelectedInsrFamIdx] = React.useState<number>()
  const [selectedInsrPrdIdx, setSelectedInsrPrdIdx] = React.useState<number>()
  const [insrPrds, setInsrPrds] = React.useState<IAPIInsuranceProduct[]>()
  const [insrPrdsLoading, setInsrPrdsLoading] = React.useState(false)
  const [insrPrdsError, setInsrPrdsError] = React.useState<string>()

  const [selectedCommissionType, setSelectedCommissionType] = React.useState<string>(
    commissionTypeStr || UCITS_NAVBAR_ITEMS[0].id
  )

  const history = useHistory()

  const insrPrdsFamGroupings = getInsrPrdsFamGroupings(insrPrds)

  React.useEffect(() => {
    updateInsrs({ setInsrsError, setInsrsLoading, setInsrs })
  }, [])

  React.useEffect(() => {
    if (
      selectedInsrIdx === undefined ||
      insrs === undefined ||
      insrs[selectedInsrIdx] === undefined
    ) {
      return
    }

    const selectedInsrId = insrs[selectedInsrIdx].id

    updateInsrPrds({
      selectedInsrId,
      setInsrPrdsError,
      setInsrPrdsLoading,
      setInsrPrds,
    })
  }, [insrs, selectedInsrIdx])

  //Legacy
  React.useEffect(() => {
    EventEmitter.subscribe(UPDATE_EVENT_INSURANCE_PRODUCTS, () => {
      if (
        selectedInsrIdx === undefined ||
        insrs === undefined ||
        insrs[selectedInsrIdx] === undefined
      ) {
        return
      }

      const selectedInsrId = insrs[selectedInsrIdx].id

      updateInsrPrds({
        selectedInsrId,
        setInsrPrdsError,
        setInsrPrdsLoading,
        setInsrPrds,
      })
    })

    return () => EventEmitter.remove(UPDATE_EVENT_INSURANCE_PRODUCTS)
  }, [insrs, selectedInsrIdx])

  React.useEffect(() => {
    if (insrs === undefined) {
      return
    }

    if (paramInsuranceProvider === undefined) {
      return
    }

    const selectedInsuranceIdx = insrs.findIndex(
      (insurance) => insurance.provider === paramInsuranceProvider
    )

    if (selectedInsuranceIdx === -1) {
      history.push(REPORTING_CONFIGURATION_URL)
      return
    }

    setSelectedInsrIdx(selectedInsuranceIdx)
  }, [history, insrs, paramInsuranceProvider])

  React.useEffect(() => {
    if (paramInsuranceProvider === undefined || insrPrdsFamGroupings === undefined) {
      return
    }

    if (paramInsuranceFamily === undefined) {
      setSelectedInsrFamIdx(undefined)
      return
    }

    const selectedInsrFamIdx = insrPrdsFamGroupings
      .map((insrPrdsFamGrouping) => insrPrdsFamGrouping.insrPrdsFamName)
      .findIndex((insrFam) => urlFormat(insrFam) === paramInsuranceFamily)

    if (selectedInsrFamIdx === -1) {
      history.push(`${REPORTING_CONFIGURATION_URL}/${paramInsuranceProvider}`)
      return
    }

    setSelectedInsrFamIdx(selectedInsrFamIdx)
  }, [history, insrPrdsFamGroupings, paramInsuranceFamily, paramInsuranceProvider])

  React.useEffect(() => {
    if (
      insuranceProductIdStr === undefined ||
      paramInsuranceProvider === undefined ||
      paramInsuranceFamily === undefined ||
      insrPrdsFamGroupings === undefined
    ) {
      return
    }

    const insuranceProductId = parseInt(insuranceProductIdStr, 10)

    if (isNaN(insuranceProductId)) {
      history.push(`${REPORTING_CONFIGURATION_URL}/${paramInsuranceProvider}`)
      return
    }

    const selectedInsuranceFamilyIdx = insrPrdsFamGroupings.findIndex(
      (insrPrdsFamGrouping) =>
        urlFormat(insrPrdsFamGrouping.insrPrdsFamName) === paramInsuranceFamily
    )

    if (selectedInsuranceFamilyIdx === -1) {
      history.push(`${REPORTING_CONFIGURATION_URL}/${paramInsuranceProvider}`)
      return
    }

    const selectedInsuranceProductIdx = insrPrdsFamGroupings[
      selectedInsuranceFamilyIdx
    ].insrPrds.findIndex((insuranceProduct) => insuranceProduct.id === insuranceProductId)

    if (selectedInsuranceProductIdx === -1) {
      history.push(
        `${REPORTING_CONFIGURATION_URL}/${paramInsuranceProvider}/${paramInsuranceFamily}`
      )
      return
    }

    setSelectedInsrPrdIdx(selectedInsuranceProductIdx)
  }, [
    history,
    insrPrdsFamGroupings,
    insuranceProductIdStr,
    paramInsuranceFamily,
    paramInsuranceProvider,
  ])

  React.useEffect(() => {
    if (paramInsuranceProvider === undefined || insuranceProductIdStr === undefined) {
      return
    }

    const insuranceProductId = parseInt(insuranceProductIdStr, 10)

    if (commissionTypeStr === undefined) {
      history.push(
        `${REPORTING_CONFIGURATION_URL}/${paramInsuranceProvider}/${paramInsuranceFamily}/${insuranceProductId}/${UCITS_NAVBAR_ITEMS[0].id}`
      )
      return
    }

    const selectedUcitsNavbarItem = UCITS_NAVBAR_ITEMS.find(
      (ucitsNavbarItem) => ucitsNavbarItem.id === commissionTypeStr
    )

    if (selectedUcitsNavbarItem === undefined) {
      history.push(
        `${REPORTING_CONFIGURATION_URL}/${paramInsuranceProvider}/${paramInsuranceFamily}/${insuranceProductId}/${UCITS_NAVBAR_ITEMS[0].id}`
      )
      return
    }

    setSelectedCommissionType(selectedUcitsNavbarItem.id)
  }, [
    commissionTypeStr,
    history,
    insuranceProductIdStr,
    paramInsuranceFamily,
    paramInsuranceProvider,
  ])

  const selectedInsuranceProduct =
    insrPrdsFamGroupings !== undefined &&
    selectedInsrFamIdx !== undefined &&
    selectedInsrPrdIdx !== undefined
      ? insrPrdsFamGroupings[selectedInsrFamIdx].insrPrds[selectedInsrPrdIdx]
      : undefined

  const selectedInsurance =
    insrs !== undefined && selectedInsrIdx !== undefined ? insrs[selectedInsrIdx] : undefined

  return (
    <LayoutMenu
      location="reportings"
      defaultMenuOpen={true}
      canClose={selectedInsrIdx !== undefined && selectedInsrPrdIdx !== undefined}
    >
      <ContractsSidePanel
        insrs={insrs}
        insrsLoading={insrsLoading}
        insrsError={insrsError}
        selectedInsrIdx={selectedInsrIdx}
        selectedInsrFamIdx={selectedInsrFamIdx}
        setSelectedInsrFamIdx={setSelectedInsrFamIdx}
        selectedInsrPrdIdx={selectedInsrPrdIdx}
        setSelectedInsrPrdIdx={setSelectedInsrPrdIdx}
        insrPrdsFamGroupings={insrPrdsFamGroupings}
        insrPrdsLoading={insrPrdsLoading}
        insrPrdsError={insrPrdsError}
        insuranceProvider={paramInsuranceProvider}
        insuranceFamily={paramInsuranceFamily}
      />

      <Box sx={{ marginLeft: '325px', minHeight: '100vh' }}>
        {selectedInsrPrdIdx !== undefined ? (
          // Legacy
          <Context.Provider
            value={{
              selectedInsuranceProduct:
                insrPrdsFamGroupings !== undefined &&
                selectedInsrFamIdx !== undefined &&
                selectedInsrPrdIdx !== undefined
                  ? insrPrdsFamGroupings[selectedInsrFamIdx].insrPrds[selectedInsrPrdIdx]
                  : undefined,
              selectedInsurance:
                insrs !== undefined && selectedInsrIdx !== undefined
                  ? insrs[selectedInsrIdx]
                  : undefined,
              insurances: insrs,
              insuranceProducts: insrPrds,
            }}
          >
            <Stack>
              {selectedInsuranceProduct !== undefined && selectedInsurance !== undefined ? (
                <ReportingSettingsHeader
                  insuranceProduct={selectedInsuranceProduct}
                  insurance={selectedInsurance}
                />
              ) : (
                <></>
              )}
              <LayoutCommission.Content>
                <ReportingSettingsNavbar
                  insuranceProvider={paramInsuranceProvider}
                  insuranceFamily={paramInsuranceFamily}
                  insuranceProductIdStr={insuranceProductIdStr}
                  selectedCommissionType={selectedCommissionType}
                  setSelectedCommissionType={setSelectedCommissionType}
                />
              </LayoutCommission.Content>
            </Stack>
          </Context.Provider>
        ) : (
          <Stack height="100vh" justifyContent="center" alignItems="center">
            <img
              width={200}
              src={`${process.env.PUBLIC_URL}/static/images/logo-sendraise-light-xl.svg`}
              alt="Sendraise logo"
            />
          </Stack>
        )}
      </Box>
    </LayoutMenu>
  )
}

export default Contracts
