import { post } from 'utils'
import { API_UCITS_FEE_URL } from 'api/constants'

export const postInsrFeeUcits = ({
  selectedInsuranceProductsIds,
  file,
  name,
  periodStart,
  category,
}: {
  selectedInsuranceProductsIds: number[]
  file: File
  name: string
  periodStart: string
  category: string | undefined
}) => {
  const body = new FormData()

  body.append('file', file)
  body.append('fileName', file.name)
  body.append('insuranceProductIds', JSON.stringify(selectedInsuranceProductsIds))
  body.append('name', name)
  body.append('periodStart', periodStart)

  if (category !== undefined) {
    body.append('extraParams', category)
  }

  return post({ url: API_UCITS_FEE_URL, body })
}
