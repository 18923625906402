import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Stack,
  Typography,
  styled,
} from '@mui/material'
import { IAPIUploadedReport } from 'api/interfaces'
import Loader from 'component/Loader'
import { AlertCircleIcon, ChevronDownIcon } from 'icons'
import { getProviderLogo } from 'utils/commissions'
import { CommissionDetail } from './CommissionDetail'
import { useMemo } from 'react'

interface IImportLine {
  uploadedReport: IAPIUploadedReport
}

export const ImportLine = ({ uploadedReport }: IImportLine) => {
  const { loading, error, id, commissions, insurance, progress } = uploadedReport

  const commissionErros = useMemo(() => {
    return commissions.reduce((acc, curr) => {
      return (acc += curr.errors)
    }, 0)
  }, [commissions])

  return (
    <StyledImportLine disableGutters square={true}>
      {/* @ts-ignore */}
      <AccordionSummary
        expandIcon={error || commissions.length > 0 ? <ChevronDownIcon opacity={0.4} /> : <></>}
        aria-controls={`updloaded-report-${id}`}
        id={id}
        sx={{ '.MuiAccordionSummary-content': { maxWidth: '100%' } }}
      >
        <Stack
          direction="row"
          alignItems="center"
          flex={1}
          gap={1}
          mr="12px"
          sx={{ maxWidth: 'calc(100% - 20px)' }}
        >
          <img
            height="24px"
            width="24px"
            src={getProviderLogo(uploadedReport.insurance!.provider)}
            alt={`${uploadedReport.insurance!.provider} logo`}
          />
          <Typography sx={{ ml: '12px' }} variant="subtitle1" noWrap={true}>
            {uploadedReport.fileName}
          </Typography>
          {!loading && !error && !commissions.length && (
            <Typography
              sx={{ ml: 'auto' }}
              variant="subtitle2_m"
              bgcolor="neutralLight.dark"
              px={1}
              py="4px"
              borderRadius="4px"
            >
              En attente d’analyse
            </Typography>
          )}
          {loading && (
            <Stack direction="row" alignItems="center" sx={{ ml: 'auto' }} flexShrink={0}>
              <Typography variant="subtitle1" sx={{ mr: 2 }} color="neutral.main">
                Analyse et import en cours ({progress} %)
              </Typography>
              <Loader />
            </Stack>
          )}
          {!loading && error && !commissions.length && (
            <Typography
              sx={{ ml: 'auto' }}
              variant="subtitle2_m"
              bgcolor="errorLight.main"
              px={1}
              py="4px"
              borderRadius="4px"
              color="errorLight.contrastText"
              flexShrink={0}
            >
              Erreur d'importation
            </Typography>
          )}
          {!loading && !error && commissions.length > 0 && !commissionErros && (
            <Typography
              sx={{ ml: 'auto' }}
              variant="subtitle2_m"
              bgcolor="successLight.main"
              px={1}
              py="4px"
              borderRadius="4px"
              color="successLight.contrastText"
              flexShrink={0}
            >
              Aucune erreur
            </Typography>
          )}
          {!loading && !error && commissions.length > 0 && commissionErros > 0 && (
            <Typography
              sx={{ ml: 'auto' }}
              variant="subtitle2_m"
              bgcolor="warningLight.main"
              px={1}
              py="4px"
              borderRadius="4px"
              color="warningLight.contrastText"
              flexShrink={0}
            >
              {commissionErros} {commissionErros > 1 ? 'erreurs' : 'erreur'}
            </Typography>
          )}
        </Stack>
      </AccordionSummary>
      {!loading && (
        <AccordionDetails>
          {error ? (
            <Stack
              direction="row"
              alignItems="start"
              bgcolor="errorLight.main"
              p={1}
              mx={4}
              borderRadius="4px"
              color="errorLight.contrastText"
              gap={1}
            >
              <AlertCircleIcon />
              <Typography variant="subtitle1" color="errorLight.contrastText" lineHeight="20px">
                {error}
              </Typography>
            </Stack>
          ) : (
            <Stack px={4}>
              {commissions.map((commission, index) => (
                <CommissionDetail
                  key={index}
                  commission={commission}
                  year={commissions[0].year}
                  month={commissions[0].month}
                  insurance={insurance}
                />
              ))}
            </Stack>
          )}
        </AccordionDetails>
      )}
    </StyledImportLine>
  )
}

const StyledImportLine = styled(Accordion)(({ theme }) => ({
  boxShadow: 'none',
  background: '#F4F4F1',
  marginBottom: theme.spacing(1),
  borderRadius: 4,

  '&::before': {
    display: 'none',
  },

  img: {
    width: 24,
    height: 24,
  },
}))
