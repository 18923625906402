import { API_USER_INSURANCE_URL } from 'api/constants'
import { IAPIInsurancesResponse } from 'api/interfaces'
import { IAPIInsuranceProductResponse } from 'api/interfaces/responses'
import { get } from 'utils'

export const getAllInsrs = () => {
  return get<IAPIInsurancesResponse>({
    url: API_USER_INSURANCE_URL,
  })
}

export const getInsrPrdsByInsr = (insrId: number) => {
  return get<IAPIInsuranceProductResponse>({
    url: `${API_USER_INSURANCE_URL}/${insrId}/products`,
  })
}
