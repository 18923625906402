import { Modal } from 'component'
import * as React from 'react'
import { DeletionStep, FinalStep } from './components'

interface IProps {
  open: boolean
  closeModal: () => void
  ucitsGridId: number
  ucitsGridItemId: number
  ucitsGridItemName: string
  ucitsGridItemIsin: string
}

const ModalDeleteRateGridItem: React.FC<IProps> = (props) => {
  const { open, closeModal, ucitsGridId, ucitsGridItemId, ucitsGridItemName, ucitsGridItemIsin } =
    props

  const [step, setStep] = React.useState(1)
  const [error, setError] = React.useState<string>()

  const resetState = () => {
    setStep(1)
  }

  const onCloseHandler = () => {
    closeModal()
    resetState()
  }

  const goToNextStep = () => {
    setStep((prevState) => prevState + 1)
  }

  return (
    <Modal
      title="Supprimer une Unité de Compte"
      open={open}
      onClose={onCloseHandler}
      modalId="ModalDeleteRateGridItem"
    >
      {step === 1 ? (
        <DeletionStep
          onCloseHandler={onCloseHandler}
          ucitsGridId={ucitsGridId}
          ucitsGridItemId={ucitsGridItemId}
          ucitsGridItemName={ucitsGridItemName}
          ucitsGridItemIsin={ucitsGridItemIsin}
          setError={setError}
          goToNextStep={goToNextStep}
        />
      ) : step === 2 ? (
        <FinalStep
          ucitsGridItemName={ucitsGridItemName}
          ucitsGridItemIsin={ucitsGridItemIsin}
          error={error}
          resetState={resetState}
        />
      ) : null}
    </Modal>
  )
}

export default ModalDeleteRateGridItem
