import { Button, styled, Typography } from '@mui/material'
import { ModalExportErrors } from 'component/Modals'
import HelpContainer from 'component/HelpContainer'
import moment from 'moment'
import React, { FC, useEffect, useState } from 'react'
import { capitalize, formatCurrencySymbol } from 'utils'
import { getProviderLogo } from 'utils/commissions'
import { IProps } from './core/docketsHeader.types'
import { Stack } from '@mui/system'
import { IAPIInsuranceFeeCommissionType } from 'api/interfaces'

const localization = require('moment/locale/fr')
moment.locale('fr', localization)

const DocketsHeader: FC<React.PropsWithChildren<IProps>> = ({
  period,
  insurance,
  selectedCommissionType,
}) => {
  const [modalExportErrorOpen, setModalExportErrorOpen] = useState(false)
  const [commissionTypeWithErrors, setCommissionTypeWithErrors] = useState<
    IAPIInsuranceFeeCommissionType[]
  >([])

  useEffect(() => {
    let commissionTypeWithErrors = []

    for (const [key, value] of Object.entries(period?.commissions)) {
      if ((value as number) > 0) {
        commissionTypeWithErrors.push(key)
      }
    }

    setCommissionTypeWithErrors(commissionTypeWithErrors as IAPIInsuranceFeeCommissionType[])
  }, [period?.commissions])

  const totalFees = period?.totalFees as { [key: string]: number }
  let totalFeeString = ''
  for (const [key, value] of Object.entries(totalFees)) {
    if (value) {
      if (totalFeeString) {
        totalFeeString += ' - '
      }
      totalFeeString += formatCurrencySymbol(value, key)
    }
  }

  return insurance && period ? (
    <>
      <DocketsHeaderContainer>
        <Stack direction="row" justifyContent="space-between" alignItems="flex-start">
          <Stack direction="column">
            <Stack direction="row" alignItems="center" spacing={3}>
              <Stack direction="row" alignItems="center" spacing={1.5}>
                <Typography variant="h2" color="secondary.contrastText">
                  {capitalize(moment(period.year + '-' + period.month + '-1').format('MMMM YYYY'))}
                </Typography>
                <img
                  width="32px"
                  height="32px"
                  src={getProviderLogo(insurance.provider)}
                  alt="Axa"
                />
              </Stack>
              <HelpContainer
                helpURL="https://busy-stop-fcc.notion.site/Types-de-commissions-support-s-par-assureur-30afc46f176d411692bc0df6810528f3"
                message="Quels sont les types de commissions supportés ?"
                size="small"
              />
            </Stack>
            <Typography variant="body1" color="secondary.contrastText" sx={{ opacity: '.7' }}>
              {totalFeeString}
            </Typography>
          </Stack>
          <Button
            size="medium"
            style={{ flexShrink: 0, marginLeft: 24 }}
            onClick={() => {
              setModalExportErrorOpen(true)
            }}
          >
            Exporter les erreurs
          </Button>
          <ModalExportErrors
            open={modalExportErrorOpen}
            onClose={() => {
              setModalExportErrorOpen(false)
            }}
            insuranceId={insurance.id}
            insuranceName={insurance.name}
            insuranceProviderLogo={getProviderLogo(insurance.provider)}
            periodYear={period.year}
            periodMonth={period.month}
            selectedCommissionType={selectedCommissionType}
            activeCommissionTypes={commissionTypeWithErrors as IAPIInsuranceFeeCommissionType[]}
          />
        </Stack>
      </DocketsHeaderContainer>
    </>
  ) : null
}

const DocketsHeaderContainer = styled(Stack)(({ theme }) => ({
  padding: `${theme.spacing(4)} ${theme.spacing(6)} ${theme.spacing(2)} ${theme.spacing(4)}`,
  background: theme.palette.background.inverted,
}))

export default DocketsHeader
