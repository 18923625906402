import { deleteUcitsRateGridItem } from './ModalDeleteRateGridItem.service'
import EventEmitter, { UPDATE_EVENT_INSURANCE_FEE_UCITS_ITEMS } from 'utils/events'

export const removeUcitsGridItem = async ({
  setError,
  setLoading,
  ucitsGridId,
  ucitsGridItemId,
  goToNextStep,
}: {
  setError: StateSetter<string | undefined>
  setLoading: StateSetter<boolean>
  ucitsGridId: number
  ucitsGridItemId: number
  goToNextStep: () => void
}) => {
  setError(undefined)
  setLoading(true)

  await deleteUcitsRateGridItem({ ucitsGridId, ucitsGridItemId }).catch(() => {
    setError(
      `Erreur lors de la suppression de l'Unité de Compte, veuillez réessayer. Si le problème persiste, contactez le support Sendraise.`
    )
  })

  setLoading(false)
  goToNextStep()

  EventEmitter.dispatch(UPDATE_EVENT_INSURANCE_FEE_UCITS_ITEMS)
}
