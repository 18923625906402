import * as React from 'react'

import {
  CheckCircleIcon,
  CollapseIcon,
  ExpandableIcon,
  InfoIcon,
  SendraiseIcon,
  XIcon,
} from 'icons'

import {
  IconButton,
  Dialog,
  DialogProps,
  DialogTitle,
  DialogContentProps,
  DialogActionsProps,
  DialogContent,
  DialogActions,
  Typography,
  Box,
  Tooltip,
  Stack,
  Button,
  styled,
} from '@mui/material'
import { ModalContext } from 'utils/hooks/modalBehavior'

interface IProps extends DialogProps {
  title: string
  stepTitle?: string
  hint?: React.ReactNode
  onClose?: NonNullable<DialogProps['onClose']>
  canClose?: boolean
  expandable?: boolean
  collapsedTitle?: string
  collapsedStepTitle?: string
  success?: boolean
  modalId: string
}

interface IComposition {
  Content: React.FC<DialogContentProps>
  Actions: React.FC<DialogActionsProps>
}

const Modal: React.FC<React.PropsWithChildren<IProps>> & IComposition = (props) => {
  const {
    title,
    children,
    stepTitle,
    hint,
    onClose,
    canClose = true,
    expandable,
    collapsedStepTitle,
    collapsedTitle,
    success,
    modalId,
    ...modalProps
  } = props

  const [collapsed, setCollapsed] = React.useState(false)
  const modal = React.useContext(ModalContext)

  React.useEffect(() => {
    if (modal.currentModal && modal.currentModal.name === modalId) {
      setCollapsed(modal.currentModal.collapsed)
    }
  }, [modal.currentModal, modalId])

  const handleModalSize = () => {
    setCollapsed(!collapsed)
  }

  return (
    <CustomDialog {...modalProps} className={collapsed ? 'collapsed' : ''}>
      <DialogTitle
        flexDirection="row"
        justifyContent="space-between"
        alignItems={collapsed ? 'center' : 'flex-start'}
        gap={collapsed ? 2 : 0}
        sx={success && collapsed ? { bgcolor: 'success.main' } : undefined}
      >
        <Stack direction="row" alignItems="center" gap={2}>
          {expandable &&
            (success && collapsed ? (
              <CheckCircleIcon sx={{ color: 'white', width: 24 }} />
            ) : (
              <SendraiseIcon sx={{ fontSize: 32 }} id="sendraise-logo" />
            ))}
          <Box lineHeight={collapsed ? '16px' : 'initial'}>
            {stepTitle && (
              <Typography
                variant={collapsed ? 'subtitle1_m' : 'body2'}
                fontWeight={500}
                sx={{ color: collapsed ? 'white' : '#B6BCC3' }}
                mb={0.5}
              >
                {collapsed ? collapsedStepTitle : stepTitle}
              </Typography>
            )}
            <Stack direction="row" alignItems="center" gap={1.5}>
              <Typography
                variant={collapsed ? 'subtitle1' : 'h2b'}
                fontWeight={collapsed ? 400 : 500}
                component="p"
                sx={{
                  color: collapsed
                    ? success && collapsed
                      ? 'neutral.contrastText'
                      : '#B6BCC3'
                    : 'white',
                }}
              >
                {collapsed ? collapsedTitle : title}
              </Typography>
              {hint ? (
                <Tooltip
                  title={hint}
                  placement="right-start"
                  slotProps={{ popper: { sx: { maxWidth: 415 } } }}
                >
                  <Stack>
                    <InfoIcon sx={{ color: 'primary.main', cursor: 'pointer' }} />
                  </Stack>
                </Tooltip>
              ) : null}
            </Stack>
          </Box>
        </Stack>
        {onClose && (!expandable || (expandable && success && !collapsed)) && (
          <IconButton
            color="secondary"
            onClick={(e) => {
              onClose(e, 'backdropClick')
            }}
            size="small"
            disabled={!canClose}
          >
            <XIcon />
          </IconButton>
        )}
        {expandable && (!success || collapsed) && (
          <Button
            color={success && collapsed ? 'inherit' : 'secondary'}
            sx={
              success && collapsed
                ? { bgcolor: 'white', color: 'neutralLight.contrastText', px: '10px' }
                : { px: '10px' }
            }
            size="small"
            onClick={handleModalSize}
          >
            {collapsed ? (
              <ExpandableIcon
                sx={{
                  mr: 1,
                  color: success && collapsed ? 'neutralLight.contrastText' : 'inherit',
                }}
              />
            ) : (
              <CollapseIcon
                sx={{
                  mr: 1,
                  color: success && collapsed ? 'neutralLight.contrastText' : 'inherit',
                }}
              />
            )}
            {collapsed ? 'Voir le détail' : 'Réduire le détail'}
          </Button>
        )}
      </DialogTitle>
      {children}
    </CustomDialog>
  )
}

Modal.Content = DialogContent
Modal.Actions = DialogActions

export default Modal

const CustomDialog = styled(Dialog)(({ theme }) => ({
  '&.collapsed': {
    pointerEvents: 'none',
    '.MuiBackdrop-root': {
      background: 'rgba(0, 0, 0, 0)',
    },
    '.MuiDialog-paper': {
      maxHeight: 76,
      width: 468,
      overflow: 'hidden',
      transform: 'translate(calc((100vw - 468px) / 2 - 24px), calc((100vh - 76px) / 2 - 24px))',
      margin: 0,
      borderRadius: 8,
    },
    '.MuiDialogTitle-root': {
      padding: `20px ${theme.spacing(3)} ${theme.spacing(2)}`,
      pointerEvents: 'all',

      '#sendraise-logo': {
        width: 32,
        opacity: 1,
      },
    },
    '.progress-bar': {
      opacity: 1,
    },
  },
  '.MuiBackdrop-root': {
    transition: 'background 0.2s cubic-bezier(0.25, 0.1, 0.25, 1)',
  },
  '.MuiDialog-paper': {
    transition: 'all 0.2s cubic-bezier(0.25, 0.1, 0.25, 1)',
    transformOrigin: 'bottom right',
    overflowX: 'auto',

    '&::-webkit-scrollbar, .MuiDialogContent-root::-webkit-scrollbar': {
      display: 'none',
      webkitAppearance: 'none',
      width: 0,
      height: 0,
    },
  },
  '.MuiDialogTitle-root': {
    transition: 'all 0.2s cubic-bezier(0.25, 0.1, 0.25, 1)',

    '#sendraise-logo': {
      transition: 'all 0.2s cubic-bezier(0.25, 0.1, 0.25, 1)',
      width: 0,
      opacity: 0,
    },
  },
}))
