import * as React from 'react'
import { Box, Chip, Stack, Tab, Tabs, Typography, styled } from '@mui/material'
import { AlertCircleIcon, LinkIcon } from 'icons'
import CommissionsTab from './CommissionsTab'
import ContractsTab from './ContractsTab'
import ErrorsTab from './ErrorsTab'
import { IAPIClientContract, IAPIClientError, IAPICommissionClient } from 'api/interfaces/entities'
import { getClientContracts, getClientErrors } from '../../core/Clients.services'
import EventEmitter, { UPDATE_EVENT_CONTRACTS } from 'utils/events'

interface IProps {
  client: IAPICommissionClient | null
}

const ClientsPanel = ({ client }: IProps) => {
  const [tab, setTab] = React.useState('commissions')
  const [automaticallyAttachedContracts, setAutomaticallyAttachedContracts] = React.useState<
    IAPIClientContract[]
  >([])
  const [manuallyAttachedContracts, setManuallyAttachedContracts] = React.useState<
    IAPIClientContract[]
  >([])
  const [activeErrors, setActiveErrors] = React.useState<IAPIClientError[]>([])
  const [deactivatedErrors, setDeactivatedErrors] = React.useState<IAPIClientError[]>([])

  // @ts-ignore
  const handleTabChange = (event: React.SyntheticEvent, newValue: string) => {
    setTab(newValue)
  }

  const fetchClientContracts = React.useCallback(async () => {
    if (!client) return
    const res = await getClientContracts({ clientId: client.id })

    if (!res) {
      return
    }
    setAutomaticallyAttachedContracts(res.automaticallyAttachedContracts)
    setManuallyAttachedContracts(res.manuallyAttachedContracts)
  }, [client])

  const fetchClientErrors = React.useCallback(async () => {
    if (!client) return
    const res = await getClientErrors({ clientId: client.id })

    if (!res) {
      return
    }
    setActiveErrors(res.activeErrors)
    setDeactivatedErrors(res.deactivatedErrors)
  }, [client])

  React.useEffect(() => {
    EventEmitter.subscribe(UPDATE_EVENT_CONTRACTS, () => {
      fetchClientContracts()
      fetchClientErrors()
    })
    fetchClientContracts()
    fetchClientErrors()
  }, [fetchClientContracts, fetchClientErrors])

  if (!client) {
    return <></>
  }

  return (
    <>
      <Box bgcolor="background.inverted">
        <Wrapper direction="row" justifyContent="space-between" alignItems="center">
          <Typography variant="h2" color="primary.contrastText">
            {client.firstName} {client.lastName}
          </Typography>
        </Wrapper>

        <CustomTab
          value={tab}
          onChange={handleTabChange}
          aria-label="client detail navigation"
          role="navigation"
        >
          <Tab
            disableRipple={true}
            label={<span>Cumul de commissions</span>}
            value="commissions"
            sx={{ fontWeight: 500 }}
          />
          <Tab
            disableRipple={true}
            value="contracts"
            sx={{ fontWeight: 500 }}
            label={
              <Stack direction="row" alignItems="center" spacing={2} color="secondary.contrastText">
                <span>Contrats</span>
                {manuallyAttachedContracts.length > 0 && (
                  <Chip
                    label={manuallyAttachedContracts.length}
                    color="default"
                    size="small"
                    icon={<LinkIcon />}
                    sx={{ ml: 1 }}
                  />
                )}
              </Stack>
            }
          />
          <Tab
            disableRipple={true}
            value="errors"
            sx={{ fontWeight: 500 }}
            label={
              <Stack direction="row" alignItems="center" spacing={2} color="secondary.contrastText">
                <span>Erreurs sur commissions</span>
                {activeErrors.length > 0 && <AlertCircleIcon sx={{ ml: 1 }} color="warning" />}
              </Stack>
            }
          />
        </CustomTab>
      </Box>
      <Box bgcolor="background.paper" flex={1}>
        {tab === 'commissions' && <CommissionsTab client={client} />}
        {tab === 'contracts' && (
          <ContractsTab
            client={client}
            automaticallyAttachedContracts={automaticallyAttachedContracts}
            manuallyAttachedContracts={manuallyAttachedContracts}
          />
        )}
        {tab === 'errors' && (
          <ErrorsTab
            client={client}
            activeErrors={activeErrors}
            deactivatedErrors={deactivatedErrors}
            setActiveErrors={setActiveErrors}
            setDeactivatedErrors={setDeactivatedErrors}
          />
        )}
      </Box>
    </>
  )
}

export default ClientsPanel

const Wrapper = styled(Stack)(({ theme }) => ({
  padding: theme.spacing(4),
}))

const CustomTab = styled(Tabs)(({ theme }) => ({
  borderBottom: 'none',
  button: {
    '& span:first-child': {
      color: theme.palette.secondary.contrastText,
      opacity: 0.7,
    },

    '&.Mui-selected span:first-child': {
      color: theme.palette.secondary.contrastText,
      opacity: 1,
    },
  },
  '.MuiTabs-indicator': {
    height: 4,
    borderRadius: `4px 4px 0px 0px`,
  },
}))
