import {
  IAPIInsuranceFeeUcits,
  IAPIInsuranceFeeUcitsItem,
  IAPIInsuranceFeeUcitsDetail,
} from 'api/interfaces'
import Loader from 'component/Loader'
import * as React from 'react'
import { formatDateLocale, useDebounce } from 'utils'
import { getProviderLogo } from 'utils/commissions'
import {
  Box,
  Button,
  Divider,
  SelectProps,
  Stack,
  styled,
  Typography,
  IconButton,
} from '@mui/material'
import { Context } from 'page/Commissions/Contracts'
import { PlusCircleIcon, ChevronLeftIcon, ChevronRightIcon } from 'icons'
import { ModalDeleteRateGridItem, ModalUploadRateGrid } from 'component/Modals'
import {
  retrieveAllUcitsGrids,
  retrieveUcitsGridDetail,
  retrieveUcitsGridItems,
} from './core/UcitsNavbarContent.helpers'
import ModalDeleteRateGird from 'component/Modals/ModalDeleteRateGrid'
import EventEmitter, {
  UPDATE_EVENT_INSURANCE_FEE_UCITS,
  UPDATE_EVENT_INSURANCE_FEE_UCITS_ITEMS,
} from 'utils/events'
import { BaseTable } from 'component/Tables'
import { percentageCell, capitalizedCell } from 'component/Tables/BaseTable'
import { Dropdown, SearchInput } from 'component/Inputs'
import { GridColDef } from '@mui/x-data-grid-premium'
import theme from 'theme'

const UcitsNavbarContent: React.FC = () => {
  const [ucitsGridsLoading, setUcitsGridsLoading] = React.useState(false)
  const [ucitsGridItemsLoading, setUcitsGridItemsLoading] = React.useState(false)
  const [error, setError] = React.useState<string>()
  const [selectedUcitsGridIdx, setSelectedUcitsGridIdx] = React.useState<number>(0)
  const [ucitsGrids, setUcitsGrids] = React.useState<IAPIInsuranceFeeUcits[]>()
  const [ucitsGridItems, setUcitsGridItems] = React.useState<IAPIInsuranceFeeUcitsItem[]>()
  const [selectedUcitsGrid, setSelectedUcitsGrid] = React.useState<IAPIInsuranceFeeUcitsDetail>()
  const [selectedUcitsGridItem] = React.useState<IAPIInsuranceFeeUcitsItem>()
  const [search, setSearch] = React.useState<string>('')
  const [modalUploadRateGridOpen, setModalUploadRateGridOpen] = React.useState(false)
  const [modalDeleteRateGridOpen, setModalDeleteRateGridOpen] = React.useState(false)
  const [modalDeleteRateGridItemOpen, setModalDeleteRateGridItemOpen] = React.useState(false)
  const debouncedSearch = useDebounce(search, 800)

  const context = React.useContext(Context)

  const { selectedInsuranceProduct, selectedInsurance, insurances, insuranceProducts } = {
    ...context,
  }

  const [paginationModel, setPaginationModel] = React.useState({
    pageSize: 25,
    page: 0,
  })
  const totalPages = ucitsGridItems ? Math.max(1, Math.ceil(ucitsGridItems.length / 25)) : 0

  const goToPreviousPage = () => {
    setPaginationModel((prevState) => {
      return {
        ...prevState,
        page: prevState.page - 1,
      }
    })
  }

  const goToNextPage = () => {
    setPaginationModel((prevState) => {
      return {
        ...prevState,
        page: prevState.page + 1,
      }
    })
  }

  const COLUMNS = React.useMemo<GridColDef[]>(
    () => [
      {
        field: 'ucitsName',
        headerName: `Libellé`,
        ...capitalizedCell,
        sortable: false,
        flex: 1,
        valueGetter: (params) => params.row.ucits.name,
      },
      {
        field: 'ucitsAssetManager',
        headerName: `Gestionnaire`,
        ...capitalizedCell,
        sortable: false,
        flex: 1,
        valueGetter: (params) => params.row.ucits.assetManager,
      },
      {
        field: 'ucitsIsin',
        headerName: `ISIN`,
        sortable: false,
        width: 140,
        valueGetter: (params) => params.row.ucits.isin,
      },
      {
        field: 'feeRate',
        headerName: `Taux de rétrocession`,
        sortable: false,
        ...percentageCell,
        align: 'right',
        headerAlign: 'right',
        width: 120,
      }
    ],
    []
  )

  React.useEffect(() => {
    setUcitsGridItems(undefined)

    if (!selectedInsuranceProduct) {
      return
    }

    retrieveAllUcitsGrids({
      setError,
      setUcitsGridsLoading,
      selectedInsuranceProductId: selectedInsuranceProduct.id,
      setUcitsGrids,
    })
  }, [selectedInsuranceProduct])

  React.useEffect(() => {
    EventEmitter.subscribe(UPDATE_EVENT_INSURANCE_FEE_UCITS, () => {
      setUcitsGridItems(undefined)

      if (!selectedInsuranceProduct) {
        return
      }

      retrieveAllUcitsGrids({
        setError,
        setUcitsGridsLoading,
        selectedInsuranceProductId: selectedInsuranceProduct.id,
        setUcitsGrids,
      })
    })

    return () => {
      EventEmitter.remove(UPDATE_EVENT_INSURANCE_FEE_UCITS)
    }
  }, [selectedInsuranceProduct])

  React.useEffect(() => {
    if (ucitsGrids === undefined || ucitsGrids.length === 0 || selectedUcitsGridIdx === null) {
      return
    }

    const selectedUcitsGridId = ucitsGrids[selectedUcitsGridIdx].id

    retrieveUcitsGridDetail({
      setError,
      setUcitsGridsLoading,
      selectedUcitsGridId,
      setSelectedUcitsGrid,
    })
  }, [selectedUcitsGridIdx, ucitsGrids])

  React.useEffect(() => {
    if (ucitsGrids === undefined || ucitsGrids.length === 0 || selectedUcitsGridIdx === null) {
      return
    }

    const selectedUcitsGridId = ucitsGrids[selectedUcitsGridIdx].id

    retrieveUcitsGridItems({
      setError,
      setUcitsGridItemsLoading,
      selectedUcitsGridId,
      setUcitsGridItems,
      search: debouncedSearch,
    })
  }, [debouncedSearch, selectedUcitsGridIdx, ucitsGrids])

  React.useEffect(() => {
    EventEmitter.subscribe(UPDATE_EVENT_INSURANCE_FEE_UCITS_ITEMS, () => {
      if (ucitsGrids === undefined || ucitsGrids.length === 0 || selectedUcitsGridIdx === null) {
        return
      }

      const selectedUcitsGridId = ucitsGrids[selectedUcitsGridIdx].id

      retrieveUcitsGridItems({
        setError,
        setUcitsGridItemsLoading,
        selectedUcitsGridId,
        setUcitsGridItems,
        search: debouncedSearch,
      })
    })

    return () => {
      EventEmitter.remove(UPDATE_EVENT_INSURANCE_FEE_UCITS_ITEMS)
    }
  }, [debouncedSearch, selectedUcitsGridIdx, ucitsGrids])

  const closeModalUploadRateGrid = () => {
    setModalUploadRateGridOpen(false)
  }

  const closeModalDeleteRateGrid = () => {
    setModalDeleteRateGridOpen(false)
  }

  const closeModalDeleteRateGridItem = () => {
    setModalDeleteRateGridItemOpen(false)
  }

  const openModalDeleteRateGrid = () => {
    setModalDeleteRateGridOpen(true)
  }

  const openModalUploadRateGrid = () => {
    setModalUploadRateGridOpen(true)
  }

  const searchChangeHandler: React.ChangeEventHandler<HTMLInputElement> = (e) => {
    setSearch(e.target.value)
  }

  const searchResetHandler: React.MouseEventHandler<SVGSVGElement> = (e) => {
    setSearch('')
  }

  const selectedUcitsGridChangeHandler: NonNullable<SelectProps['onChange']> = (e) => {
    const idx = Number(e.target.value)

    if (isNaN(idx)) {
      return
    }

    setSelectedUcitsGridIdx(idx)
  }

  return insurances !== undefined &&
    insuranceProducts !== undefined &&
    ucitsGrids !== undefined &&
    selectedInsurance !== undefined &&
    selectedInsuranceProduct !== undefined ? (
    <>
      {ucitsGridsLoading ? (
        <Stack alignItems="center" justifyContent="center">
          <Loader />
        </Stack>
      ) : error ? (
        <Typography color="error.text">{error}</Typography>
      ) : (
        <Stack gap={3}>
          <Stack gap={1}>
            {ucitsGrids.length !== 0 ? (
              <Typography variant="body2" fontWeight={500}>
                Grilles{' '}
                <Typography component="span" variant="body2" color="text.secondary">
                  ({ucitsGrids.length})
                </Typography>
              </Typography>
            ) : null}
            <Stack direction="row" justifyContent="space-between" alignItems="center">
              {ucitsGrids.length !== 0 && selectedUcitsGrid !== undefined ? (
                <Stack direction="row" alignItems="center" gap={4}>
                  <Dropdown
                    id="ucits-grid"
                    value={selectedUcitsGridIdx.toString()}
                    onChange={selectedUcitsGridChangeHandler}
                    options={ucitsGrids.map((ucitsGrids, idx) => {
                      return {
                        display: ucitsGrids.fileName,
                        value: idx.toString(),
                        icon: (
                          <img
                            width={20}
                            height={20}
                            src={getProviderLogo(selectedInsurance.provider)}
                            alt={`${selectedInsurance.name} logo`}
                          />
                        ),
                      }
                    })}
                  />
                  <Stack gap={0.5}>
                    <Typography variant="sectionHeading" color="text.secondary">
                      Ajoutée le
                    </Typography>
                    <Typography variant="subtitle1">
                      {formatDateLocale(ucitsGrids[selectedUcitsGridIdx].createdAt)}
                    </Typography>
                  </Stack>
                  <Stack gap={0.5}>
                    <Typography variant="sectionHeading" color="text.secondary">
                      Date d'effet
                    </Typography>
                    <Typography variant="subtitle1">
                      {formatDateLocale(selectedUcitsGrid.periodStart)}
                    </Typography>
                  </Stack>
                </Stack>
              ) : (
                <Typography variant="body2">
                  Aucune grille n'a été importée pour ce produit d'assurance
                </Typography>
              )}
              <Stack direction="row" alignItems="center" gap={2}>
                {ucitsGrids.length !== 0 && (
                  <Button color="error" variant="text" onClick={openModalDeleteRateGrid}>
                    Supprimer cette grille
                  </Button>
                )}
                <Button startIcon={<PlusCircleIcon />} onClick={openModalUploadRateGrid}>
                  Ajouter une grille
                </Button>
              </Stack>
            </Stack>
          </Stack>
          {ucitsGrids.length !== 0 ? <Divider /> : null}
          {ucitsGridItems !== undefined ? (
            <Stack gap={2}>
              <Stack direction="row" alignItems="center" justifyContent="space-between">
                <SearchInput
                  id="search"
                  value={search}
                  onChange={searchChangeHandler}
                  onReset={searchResetHandler}
                />

                {totalPages > 1 && (
                  <Box
                    className="table-commissions__pagination"
                    sx={{
                      display: 'flex',
                      flexDirection: 'row',
                      alignItems: 'center',
                      gap: theme.spacing(1),
                    }}
                  >
                    <Typography variant="subtitle2" color="text.secondary">
                      {paginationModel.page + 1} sur {totalPages}
                    </Typography>
                    <IconButton
                      color="neutral"
                      size="small"
                      disabled={paginationModel.page === 0}
                      onClick={goToPreviousPage}
                    >
                      <ChevronLeftIcon />
                    </IconButton>
                    <IconButton
                      color="neutral"
                      size="small"
                      disabled={paginationModel.page + 1 >= totalPages}
                      onClick={goToNextPage}
                    >
                      <ChevronRightIcon />
                    </IconButton>
                  </Box>
                )}
              </Stack>
              {!ucitsGridItemsLoading ? (
                <UcitsGridTable
                  columns={COLUMNS}
                  autoHeight
                  rows={ucitsGridItems}
                  noContentMessage="Aucune commission sur OPCVM ne correspond à votre recherche"
                  pagination={true}
                  paginationModel={paginationModel}
                  onPaginationModelChange={setPaginationModel}
                />
              ) : (
                <Stack alignItems="center" justifyContent="center">
                  <Loader />
                </Stack>
              )}
            </Stack>
          ) : ucitsGrids.length !== 0 ? (
            <Stack alignItems="center" justifyContent="center">
              <Loader />
            </Stack>
          ) : null}
        </Stack>
      )}
      <ModalUploadRateGrid
        open={modalUploadRateGridOpen}
        closeModal={closeModalUploadRateGrid}
        insuranceName={selectedInsurance.name}
        insuranceProvider={selectedInsurance.provider}
        insuranceHelpURL={selectedInsurance.helpURL}
        insuranceProductId={selectedInsuranceProduct.id}
        insuranceProductName={selectedInsuranceProduct.name}
        insuranceProducts={insuranceProducts}
      />
      {selectedUcitsGrid !== undefined ? (
        <ModalDeleteRateGird
          open={modalDeleteRateGridOpen}
          closeModal={closeModalDeleteRateGrid}
          providerLogo={getProviderLogo(selectedInsurance.provider)}
          gridName={selectedUcitsGrid.fileName}
          insuranceName={selectedInsurance.name}
          insuranceProductId={selectedInsuranceProduct.id}
          deletedRateGrid={selectedUcitsGrid}
        />
      ) : null}
      {selectedUcitsGridItem !== undefined && selectedUcitsGrid !== undefined ? (
        <ModalDeleteRateGridItem
          open={modalDeleteRateGridItemOpen}
          closeModal={closeModalDeleteRateGridItem}
          ucitsGridId={selectedUcitsGrid.id}
          ucitsGridItemId={selectedUcitsGridItem.id}
          ucitsGridItemName={selectedUcitsGridItem.ucits.name}
          ucitsGridItemIsin={selectedUcitsGridItem.ucits.isin}
        />
      ) : null}
    </>
  ) : null
}

export default UcitsNavbarContent

const UcitsGridTable = styled(BaseTable)(({ theme }) => ({
  '.MuiDataGrid-cell': {
    "&[data-field='ucitsName'], &[data-field='feeRate']": {
      fontWeight: 500,
    },
    "&[data-field='ucitsIsin']": {
      color: theme.palette.text.secondary,
    },

    "&[data-field='feeRate']": {
      justifyContent: 'flex-end',
    },
  },

  '.MuiDataGrid-columnHeaderTitle': {
    overflow: 'visible',
    whiteSpace: 'normal',
    textAlign: 'right',
  },
}))
