import {
  IAPIInsuranceFeeUcits,
  IAPIInsuranceFeeUcitsItem,
  IAPIInsuranceFeeUcitsDetail,
} from 'api/interfaces'

import { getUcitsGridDetail, getUcitsGridItems, getUcitsGrids } from './UcitsNavbarContent.service'

export const retrieveAllUcitsGrids = async ({
  setError,
  setUcitsGridsLoading,
  selectedInsuranceProductId,
  setUcitsGrids,
}: {
  setError: StateSetter<string | undefined>
  setUcitsGridsLoading: StateSetter<boolean>
  selectedInsuranceProductId: number
  setUcitsGrids: StateSetter<IAPIInsuranceFeeUcits[] | undefined>
}) => {
  setError(undefined)
  setUcitsGridsLoading(true)

  const res = await getUcitsGrids(selectedInsuranceProductId).catch(() => {
    setError(
      `Erreur lors de la récupération des grilles de taux, veuillez rafraichir la page. Si le problème persiste, contactez le support Sendraise.`
    )
  })

  setUcitsGridsLoading(false)

  if (!res) {
    return
  }

  setUcitsGrids(res)
}

export const retrieveUcitsGridDetail = async ({
  setError,
  setUcitsGridsLoading,
  selectedUcitsGridId,
  setSelectedUcitsGrid,
}: {
  setError: StateSetter<string | undefined>
  setUcitsGridsLoading: StateSetter<boolean>
  selectedUcitsGridId: number
  setSelectedUcitsGrid: StateSetter<IAPIInsuranceFeeUcitsDetail | undefined>
}) => {
  setError(undefined)
  setUcitsGridsLoading(true)

  const res = await getUcitsGridDetail(selectedUcitsGridId).catch(() => {
    setError(
      `Erreur lors de la récupération du detail de la grille de taux, veuillez rafraichir la page. Si le problème persiste, contactez le support Sendraise.`
    )
  })

  setUcitsGridsLoading(false)

  if (!res) {
    return
  }

  setSelectedUcitsGrid(res)
}

export const retrieveUcitsGridItems = async ({
  setError,
  setUcitsGridItemsLoading,
  selectedUcitsGridId,
  setUcitsGridItems,
  search,
}: {
  setError: StateSetter<string | undefined>
  setUcitsGridItemsLoading: StateSetter<boolean>
  selectedUcitsGridId: number
  setUcitsGridItems: StateSetter<IAPIInsuranceFeeUcitsItem[] | undefined>
  search: string
}) => {
  setError(undefined)
  setUcitsGridItemsLoading(true)

  const res = await getUcitsGridItems(selectedUcitsGridId, search).catch(() => {
    setError(
      `Erreur lors de la récupération des lignes de la grille de taux, veuillez rafraichir la page. Si le problème persiste, contactez le support Sendraise.`
    )
  })

  setUcitsGridItemsLoading(false)

  if (!res) {
    return
  }

  setUcitsGridItems(res)
}
