import * as React from 'react'

import { History } from 'history'
import { IAPIInsurance, IAPIInsuranceProduct } from 'api/interfaces'
import { IFetchError, urlFormat } from 'utils'

import { getAllInsrs, getInsrPrdsByInsr } from './Contracts.service'

import { REPORTING_CONFIGURATION_URL } from 'utils/router/constants'

export const updateInsrs = async ({
  setInsrsError,
  setInsrsLoading,
  setInsrs,
}: {
  setInsrsError: StateSetter<string | undefined>
  setInsrsLoading: StateSetter<boolean>
  setInsrs: StateSetter<IAPIInsurance[] | undefined>
}) => {
  setInsrsLoading(true)
  setInsrsError(undefined)

  const res = await getAllInsrs().catch((err: IFetchError) => {
    console.log(err.details)
    setInsrsError(
      'Erreur lors de la récupération de la liste des assurances, veuillez réessayer. Si le problème persiste, contactez le support Sendraise.'
    )
  })

  setInsrsLoading(false)

  if (!res) {
    return
  }

  setInsrs(res.results)
}

export const updateInsrPrds = async ({
  selectedInsrId,
  setInsrPrdsError,
  setInsrPrdsLoading,
  setInsrPrds,
}: {
  selectedInsrId: number
  setInsrPrdsError: StateSetter<string | undefined>
  setInsrPrdsLoading: StateSetter<boolean>
  setInsrPrds: StateSetter<IAPIInsuranceProduct[] | undefined>
}) => {
  setInsrPrdsLoading(true)
  setInsrPrdsError(undefined)

  const res = await getInsrPrdsByInsr(selectedInsrId).catch(() => {
    setInsrPrdsError(
      'Erreur lors de la récupération de la liste des produits assurances, veuillez réessayer. Si le problème persiste, contactez le support Sendraise.'
    )
  })

  setInsrPrdsLoading(false)

  if (!res) {
    return
  }

  setInsrPrds(res.results)
}

export const handleInsrClick = ({
  insrs,
  history,
  insrIdx,
  setSelectedInsrPrdIdx,
  setSelectedInsrFamIdx,
}: {
  insrs: IAPIInsurance[] | undefined
  history: History
  insrIdx: number
  setSelectedInsrPrdIdx: StateSetter<number | undefined>
  setSelectedInsrFamIdx: StateSetter<number | undefined>
}): React.MouseEventHandler => {
  return () => {
    if (insrs === undefined) {
      return
    }

    setSelectedInsrPrdIdx(undefined)
    setSelectedInsrFamIdx(undefined)
    history.push(`${REPORTING_CONFIGURATION_URL}/${insrs[insrIdx].provider}`)
  }
}

export const handleInsrFamClick = ({
  history,
  insuranceProvider,
  insuranceFamily,
  insrPrdsFams,
  famIdx,
  setSelectedInsrPrdIdx,
}: {
  history: History
  insuranceProvider: string | undefined
  insuranceFamily: string | undefined
  insrPrdsFams: string[]
  famIdx: number
  setSelectedInsrPrdIdx: StateSetter<number | undefined>
}): React.MouseEventHandler => {
  return () => {
    if (insuranceProvider === undefined) {
      return
    }

    const newInsuranceFamily = urlFormat(insrPrdsFams[famIdx])

    if (newInsuranceFamily === insuranceFamily) {
      history.push(`${REPORTING_CONFIGURATION_URL}/${insuranceProvider}`)
      return
    }

    setSelectedInsrPrdIdx(undefined)
    history.push(
      `${REPORTING_CONFIGURATION_URL}/${insuranceProvider}/${urlFormat(newInsuranceFamily)}`
    )
  }
}

export const handleInsrPrdClick = ({
  history,
  insuranceProvider,
  insuranceFamily,
  insrPrds,
  insrPrdIdx,
}: {
  history: History
  insuranceProvider: string | undefined
  insuranceFamily: string | undefined
  insrPrds: IAPIInsuranceProduct[]
  insrPrdIdx: number
}): React.MouseEventHandler => {
  return () => {
    if (insuranceProvider === undefined) {
      return
    }

    history.push(
      `${REPORTING_CONFIGURATION_URL}/${insuranceProvider}/${insuranceFamily}/${insrPrds[insrPrdIdx].id}`
    )
  }
}

export const handleTabChange = ({
  history,
  insuranceProvider,
  insuranceFamily,
  insuranceProductIdStr,
}: {
  history: History
  insuranceProvider: string | undefined
  insuranceFamily: string | undefined
  insuranceProductIdStr: string | undefined
}) => {
  return (commissionId: string) => {
    if (
      insuranceProvider === undefined ||
      insuranceFamily === undefined ||
      insuranceProductIdStr === undefined
    ) {
      return
    }

    const insuranceProductId = parseInt(insuranceProductIdStr, 10)

    history.push(
      `${REPORTING_CONFIGURATION_URL}/${insuranceProvider}/${insuranceFamily}/${insuranceProductId}/${commissionId}`
    )
  }
}
