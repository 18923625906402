import * as React from 'react'

import { Moment } from 'moment'
import { IFetchError } from 'utils'
import EventEmitter, { UPDATE_EVENT_INSURANCE_PRODUCTS } from 'utils/events'

import { postInsrFeeUcits } from './ModalUploadRateGrid.service'

import { DatePickerProps } from '@mui/x-date-pickers-pro'

export const handleLoadFilesClick = (
  ref: React.RefObject<HTMLInputElement>
): React.MouseEventHandler => {
  return () => {
    if (ref.current === null) {
      return
    }

    ref.current.click()
  }
}

export const handleNameChange = (
  setName: StateSetter<string>
): React.ChangeEventHandler<HTMLInputElement> => {
  return (e) => {
    setName(e.target.value)
  }
}

export const handleInputFileChange = (
  setFile: StateSetter<File | undefined>
): React.ChangeEventHandler<HTMLInputElement> => {
  return (e) => {
    if (!e.target.files || e.target.files.length === 0) {
      return
    }

    setFile(e.target.files[0])
  }
}

export const handleFileUploaderFileChange = (
  setFile: StateSetter<File | undefined>
): ((file: File) => void) => {
  return (file) => {
    setFile(file)
  }
}

export const handleFileDelete = (
  setFile: StateSetter<File | undefined>
): React.MouseEventHandler => {
  return () => {
    setFile(undefined)
  }
}

export const handlePeriodStartChange = (
  setPeriodStart: StateSetter<Moment | null>
): DatePickerProps<any, Moment>['onChange'] => {
  return (value) => {
    setPeriodStart(value)
  }
}

export const importFeeUcits = async ({
  selectedInsuranceProductsIds,
  setError,
  file,
  name,
  periodStart,
  category,
  goToNextStep,
}: {
  selectedInsuranceProductsIds: number[]
  setError: StateSetter<string | undefined>
  file: File
  name: string
  periodStart: Moment
  category: string | undefined
  goToNextStep: () => void
}) => {
  setError(undefined)

  await postInsrFeeUcits({
    selectedInsuranceProductsIds,
    file,
    name,
    periodStart: periodStart.toISOString(),
    category,
  }).catch((err: IFetchError) => {
    console.log(err.details)
    setError(
      `Erreur lors de l'upload de la grille de taux, veuillez réessayer. Si le problème persiste, contactez le support Sendraise.`
    )
  })

  goToNextStep()

  EventEmitter.dispatch(UPDATE_EVENT_INSURANCE_PRODUCTS)
}

export const getChipLabel = (selectedInsuranceProductsCount: number) => {
  if (selectedInsuranceProductsCount > 1) {
    return `${selectedInsuranceProductsCount} contrats sélectionnés`
  }

  return `${selectedInsuranceProductsCount} contrat sélectionné`
}
